export class UnsyncedAuditTrailEntity {

    public id: number = 0;
    public userId: number = 0;//we need this and user name because the user may have been deleted
    public userName: string = "";//we need this to differentiate in future any duplicate that may arise in future
    public userGroupId: number = 0;//needed cause the user may have been deleted
    public operationId: number = 0;
    public entityName: string = "";
    public entityValues: string = "";
    public entityId: number = 0;//long cause some entities have bigint as their ids
    public entityIdCombined: string = "";

}