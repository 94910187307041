export class TeacherEntity {
    public id: number = 0;
    public name: string = "";
    public initials: string = "";
    public email: string = "";
    public userId: number = 0;
    public phone: string = "";
    public tscNum: number = 0;
    public active: boolean = false;

    public constructor() {
    }

}