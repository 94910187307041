import { Injectable } from '@angular/core';
import { Router } from '@angular/router'; 
import { Observable } from 'rxjs';
import { AppwebworkerService } from './appwebworker.service';
import { Globals } from './Globals';
import { LocalstorageService } from './localstorage.service';
import { TeacherEntity } from './model/TeacherEntity';
import { UserEntity } from './model/UserEntity';

@Injectable({
  providedIn: 'root'
})
export class AppauthenticatorService {

  constructor(private localStorage: LocalstorageService, private appWebworker: AppwebworkerService, private router: Router) {
    localStorage.storagePath = "conloopsis"

    Globals.schoolId = this.getSavedSchoolId()
    let savedUser: UserEntity | null = this.getSavedUser();
    //if no saved user then just create a new user object. User object should never be null because of templates
    if (savedUser !== null && savedUser.id > 0) {
      Globals.userEntity = savedUser;
      //reconstruct the token
      Globals.token = Globals.appId + "-" + Globals.schoolId + "-" + Globals.userEntity.id + "-" + Globals.userEntity.groupId;
    }
    this.signInSavedUser();
  }

  public signInSavedUser(): void {

    this.appWebworker.signInUser(Globals.schoolId, Globals.userEntity.username, Globals.userEntity.password).subscribe((res: string) => {
      console.log("authenticator Signin: " + res.trim());

      if (!res.includes("success")) {
        this.signOut();
      }
    });

  }

  public signOut(): void {
    Globals.schoolId = 0;
    Globals.userEntity.id = 0;
    Globals.token = ""; //todo. tokens are only used during development for now, but when hosted, should be disabled
    this.saveAuthenticatorDetails(0, new UserEntity());
    this.saveTeacher(new TeacherEntity());
    this.router.navigate(['signin']);
  }

  public saveAuthenticatorDetails(schoolId: number, userEntity: UserEntity): void {
    try {
      this.localStorage.setItem("schoolid", schoolId.toString());
      this.localStorage.setItem("userentity", JSON.stringify(userEntity));
    } catch (e) {
      //todo. log any error
    }
  }


  private getSavedSchoolId(): number {
    try {
      let strSchoolId: string | null = this.localStorage.getItem("schoolid");
      if (strSchoolId !== null) {
        return parseInt(strSchoolId)
      }
    } catch (e) {
      //todo. log any error
    }
    return 0;
  }


  private getSavedUser(): UserEntity | null {
    let eUser: UserEntity | null = null;
    try {
      let strUser: string | null = this.localStorage.getItem("userentity");
      if (strUser !== null) {
        eUser = JSON.parse(strUser);
        //check if at least any of the class variable exists after parsing.
        if (eUser === null || eUser.id === 0) {
          eUser = null;
        }
      }
    } catch (e) {
      //todo. log any error
    }
    return eUser;
  }


  //---------todo, find where to add these 2 method later----
  public saveTeacher(teacherEntity: TeacherEntity): void {
    try {
      this.localStorage.setItem("teacherentity", JSON.stringify(teacherEntity));
    } catch (e) {
      //todo. log any error
    }
  }

  public getSavedTeacher(): TeacherEntity | null {
    let eUteacher: TeacherEntity | null = null;
    try {
      let strUser: string | null = this.localStorage.getItem("teacherentity");
      if (strUser !== null) {
        eUteacher = JSON.parse(strUser);
        //check if at least any of the class variable exists after parsing.
        if (eUteacher === null || eUteacher.id === 0) {
          eUteacher = null;
        }
      }
    } catch (e) {
      //todo. log any error
    }
    return eUteacher;
  }

}


