import { Component, Input, OnInit } from '@angular/core';
import { AppauthenticatorService } from '../appauthenticator.service';

@Component({
  selector: 'app-maintoolbar',
  templateUrl: './maintoolbar.component.html',
  styleUrls: ['./maintoolbar.component.css']
})
export class MaintoolbarComponent implements OnInit {
  @Input() bShowAccountMenuItem: boolean = false;

  constructor(private appAuthenticator: AppauthenticatorService) { }

  ngOnInit(): void {
  }

  public onClickSignOut(): void {
    this.appAuthenticator.signOut();
  }

}
