export class TeacherMarkBookEntryEntity {
    public teacherUserId: number = 0;//long
    public subjectId: number = 0;//int
    public classId: number = 0;//int
    public examId: number = 0;//int
    public yearId: number = 0;//int
    public termId: number = 0;//int
    public singlePaper: boolean = false;
    public paper1: boolean = false;
    public paper2: boolean = false;
    public paper3: boolean = false;
    public singlePaperOutOf: number = 0;
    public paper1OutOf: number = 0;//double
    public paper2OutOf: number = 0;//double
    public paper3OutOf: number = 0;//double
    public formulaId: number = 0;//int
    public paper1Contributes: number = 0;//double
    public paper2Contributes: number = 0;//double
    public paper3Contributes: number = 0;//double
    public x: number = 1;//int
    public y: number = 1;//int
    public orderNum: number = 0;//int
}