import { NavigateBundleMarksEntry } from "./model/NavigateBundleMarksEntry";
import { UserEntity } from "./model/UserEntity";

export class Globals {

    public static appId: number = 3;//pwa 
    public static schoolId: number = 0;
    public static userEntity: UserEntity= new UserEntity() ;
    public static token: string="";
    public static navigateBundle: NavigateBundleMarksEntry = new NavigateBundleMarksEntry();


}