import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppwebworkerService } from '../appwebworker.service';
import { Globals } from '../Globals';
import { MarksEntity } from '../model/MarksEntity';
import { StudentMarksView } from '../model/StudentMarksView';
import { TeacherMarkBookEntryEntity } from '../model/TeacherMarkBookEntryEntity';
import { UnsyncedAuditTrailEntity } from '../model/UnsyncedAuditTrailEntity';

@Component({
  selector: 'app-marksentry',
  templateUrl: './marksentry.component.html',
  styleUrls: ['./marksentry.component.css']
})

export class MarksentryComponent implements OnInit {

  public strSelectionTitle: string = "";
  public arrMarksViews: StudentMarksView[] = [];
  public outOfControl: FormControl;
  public bMarkbookCreated: boolean = false;
  public bLoading: boolean = false;

  constructor(private router: Router, private appWebWorker: AppwebworkerService) {
    this.outOfControl = new FormControl(100, [Validators.required, Validators.min(1)]);
    this.loadAndSetData();
  }

  ngOnInit(): void {


  }

  public onOutOfFocusLost(): void {

    if (!this.validateOutOf()) {
      return;
    }

    let outOf: number = parseFloat(this.outOfControl.value);
    //re-save all the marks with new out of
    this.arrMarksViews.forEach((row: StudentMarksView) => {
      if (row.outOf !== outOf) {
        this.saveMark(row);
      }
    });
  }

  private validateOutOf(): boolean {
    if (isNaN(this.outOfControl.value)) {
      return false;
    }

    if (this.outOfControl.value < 1) {
      return false;
    }

    return true;

  }

  public getOutOfErrorMessage(): string {
    if (this.outOfControl.hasError('required')) {
      return 'You must enter the out of';
    } else if (this.outOfControl.hasError('number')) {
      return 'Not a valid out of';
    } else if (this.outOfControl.value < 1) {
      return 'Out of must be > 0';
    } else {
      return '';
    }
  }



  private loadAndSetData(): void {
    //reroute to home page to start the process again
    if (Globals.navigateBundle.yearId == 0) {
      this.router.navigate(['home']);
      return;
    }

    this.strSelectionTitle = Globals.navigateBundle.subjectAbbrv + " " + Globals.navigateBundle.classAbbrv
      + " " + Globals.navigateBundle.yearId + " Term " + Globals.navigateBundle.termId + " > " + Globals.navigateBundle.examAbbrv

    this.bMarkbookCreated = false;
    this.bLoading = true;
    this.outOfControl.setValue(100, { emitEvent: false });

    this.appWebWorker.getStudentsMarks(Globals.navigateBundle.teacherUserId, Globals.navigateBundle.subjectId,
      Globals.navigateBundle.classId, Globals.navigateBundle.examId, Globals.navigateBundle.yearId, Globals.navigateBundle.termId, false).subscribe((res: string) => {

        this.bLoading = false;
        try {

          //console.log("marks entry response: " + res);
          if (res.includes("nosuchview") || res.includes("signin")) {
            //todo. highly unlikely but what should be done? redirect to home?
            return;
          }

          this.arrMarksViews = JSON.parse(res.trim());
          let outOf: number = 100;
          if (this.arrMarksViews.length > 0 && this.arrMarksViews[0].outOf > 0) {
            outOf = this.arrMarksViews[0].outOf;
          }

          //console.log("Updated out of: " + outOf);

          //always set the new out of
          this.outOfControl.setValue(outOf, { emitEvent: false });
          this.arrMarksViews.forEach((row: StudentMarksView) => {
            row.outOf = outOf;
          });

        } catch (e) {
          console.log("Error detected when loading marks entry");
        }


      });

  }//end method


  public OnEnter(marks: StudentMarksView): void {

    //todo. move to the next input box if available
    //console.log("enter clicked: " + marks.marks);
  }

  public OnMarksChanged(marks: StudentMarksView): void {

    let outOf: number = 100;
    if (this.validateOutOf()) {
      outOf = parseFloat(this.outOfControl.value);
    }

    marks.outOf = outOf;

    if (!this.bMarkbookCreated) {
      this.saveMarkBook(outOf);
      this.bMarkbookCreated = true;
    }

    this.saveMark(marks);
  }


  private saveMark(marksView: StudentMarksView) {

    //console.log("saving marks: " + marks.marks);

    let marksEntity: MarksEntity = new MarksEntity();
    //very important to change teacherUserId(incase in future we want user to also see marks of other teachers),
    //because it may have been another user id, getting of marks from server accepts 0 for the user id
    marksEntity.teacherUserId = Globals.navigateBundle.teacherUserId;
    marksEntity.subjectId = marksView.subjectId;
    marksEntity.examId = marksView.examId;
    marksEntity.yearId = marksView.yearId;
    marksEntity.termId = marksView.termId;
    marksEntity.studentId = marksView.studentId;
    marksEntity.marks = marksView.marks;
    marksEntity.outOf = marksView.outOf;
    marksEntity.symbol = marksView.symbol
    marksEntity.paper1Marks = marksView.paper1Marks;
    marksEntity.paper1OutOf = marksView.paper1OutOf;
    marksEntity.paper1Symbol = marksView.paper1Symbol;
    marksEntity.paper2Marks = marksView.paper2Marks;
    marksEntity.paper2OutOf = marksView.paper2OutOf;
    marksEntity.paper2Symbol = marksView.paper2Symbol;
    marksEntity.paper3Marks = marksView.paper3Marks;
    marksEntity.paper3OutOf = marksView.paper3OutOf;
    marksEntity.paper3Symbol = marksView.paper3Symbol;


    //create the new unsynced audit trail entity
    //please note, there is no need to increment the id cause it auto increments
    let unsyncedEntity: UnsyncedAuditTrailEntity = new UnsyncedAuditTrailEntity();

    unsyncedEntity.userId = Globals.userEntity.id;
    unsyncedEntity.userName = Globals.userEntity.username;
    unsyncedEntity.userGroupId = Globals.userEntity.groupId;
    unsyncedEntity.operationId = 1;
    unsyncedEntity.entityName = "MarksEntity";
    unsyncedEntity.entityValues = JSON.stringify(marksEntity);
    unsyncedEntity.entityIdCombined = marksEntity.studentId + "" + marksEntity.subjectId + "" + marksEntity.examId + "" + marksEntity.yearId + "" + marksEntity.termId;


    //0 means deleted
    if (marksEntity.marks == 0) {
      unsyncedEntity.operationId = 3;
    }

    this.saveEntity(unsyncedEntity);
  }//end method


  private saveMarkBook(outof: number) {
    //console.log("saving markbook");
    let entity: TeacherMarkBookEntryEntity = new TeacherMarkBookEntryEntity();
    entity.teacherUserId = Globals.navigateBundle.teacherUserId;
    entity.subjectId = Globals.navigateBundle.subjectId;
    entity.classId = Globals.navigateBundle.classId;
    entity.yearId = Globals.navigateBundle.yearId;
    entity.termId = Globals.navigateBundle.termId;

    entity.singlePaper = true;
    entity.singlePaperOutOf = outof;
    entity.formulaId = 1;


    //create the new unsynced audit trail entity
    //please note, there is no need to increment the id cause it auto increments
    let unsyncedEntity: UnsyncedAuditTrailEntity = new UnsyncedAuditTrailEntity();

    unsyncedEntity.userId = Globals.userEntity.id;
    unsyncedEntity.userName = Globals.userEntity.username;
    unsyncedEntity.userGroupId = Globals.userEntity.groupId;
    unsyncedEntity.operationId = 1;
    unsyncedEntity.entityName = "TeacherMarkBookEntryEntity";
    unsyncedEntity.entityValues = JSON.stringify(entity);
    unsyncedEntity.entityIdCombined = entity.teacherUserId + "" + entity.classId + "" + entity.subjectId + "" + entity.examId + "" + entity.yearId + "" + entity.termId;
    this.saveEntity(unsyncedEntity);


  }//end method

  private saveEntity(unsyncedEntity: UnsyncedAuditTrailEntity) {
    this.appWebWorker.saveEntity(unsyncedEntity).subscribe((res: string) => {
      try {

        //console.log("entry response: " + res.trim());

        if (res.toLowerCase().includes("error") || res.toLowerCase().includes("signin")) {
          //todo. notify could not be saved
          return;
        }

        let arrAuditIds: number[] = JSON.parse(res.trim());
        console.log("ID: " + arrAuditIds[0]);



      } catch (e) {
        console.log("Error detected saving marks");
      }


    });

  }

}
