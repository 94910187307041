<div class="theme-page-container">
    <app-selectiontoolbar [strToolBarTitle]="'Marks Entry'" [strSelectionTitle]="strSelectionTitle" [bShowSelectionButtons]="false"></app-selectiontoolbar>

    <div class="theme-page-body">
        <div>
            <div class="out-of-input">
                <mat-form-field appearance="fill">
                    <mat-label>Out of</mat-label>
                    <input type="number" matInput placeholder="" [formControl]="outOfControl" required (focusout)="onOutOfFocusLost()" />
                    <mat-error *ngIf="outOfControl.invalid">{{getOutOfErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>
            <hr>
        </div>
        <div class="theme-card-content">
            <div *ngIf="bLoading" class="theme-spinner">
                <mat-spinner></mat-spinner>
            </div>
            <div *ngFor="let marksView of arrMarksViews">
                <div class="marks-input">
                    <app-marksentryinput [attr.id]="marksView.studentId" [objMarksView]="marksView" (onMarksChanged)="OnMarksChanged($event)" (onEnterPressed)="OnEnter($event)"></app-marksentryinput>
                </div>
            </div>
        </div>
    </div>

</div>