import { TeacherMarkBookEntryEntity } from "./TeacherMarkBookEntryEntity";

export class NavigateBundleMarksEntry {
    public teacherName: string = "";
    public teacherUserId: number = 0;//long
    public teacherId: number = 0;
    public subjectId: number = 0;
    public subjectCode: number = 0;
    public subjectAbbrv: string = "";
    public classId: number = 0;
    public classAbbrv: string = "";
    public yearId: number = 0;
    public termId: number = 0;
    public examId: number = 0;
    public examAbbrv: string = "";
    public markBookEntryEntity: TeacherMarkBookEntryEntity | null = null;
}