import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppauthenticatorService } from '../appauthenticator.service';
import { AppwebworkerService } from '../appwebworker.service';
import { Globals } from '../Globals';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  public signInForm: FormGroup;
  public schoolCodeControl: FormControl;
  public usernameControl: FormControl;
  public passwordControl: FormControl;
  public strErrorMessage: string = "";

  constructor(private appAuthenticator: AppauthenticatorService, private appWebworkerService: AppwebworkerService, private router: Router) {

    this.schoolCodeControl = new FormControl("", [Validators.required]);
    this.usernameControl = new FormControl("", [Validators.required]);
    this.passwordControl = new FormControl("", [Validators.required]);


    this.signInForm = new FormGroup({
      schoolCodeControl: this.schoolCodeControl,
      usernameControl: this.usernameControl,
      passwordControl: this.passwordControl
    });

    //important re-initialise key variable
    Globals.navigateBundle.teacherUserId = 0;
    Globals.navigateBundle.teacherId = 0;
    Globals.navigateBundle.yearId = 0;
    Globals.navigateBundle.termId = 0;


  }

  ngOnInit(): void {
  }

  public getSchoolCodeErrorMessage(): string {
    if (this.schoolCodeControl.hasError('required')) {
      return 'You must enter the school code';
    }
    return this.schoolCodeControl.hasError('number') ? 'Not a valid school code' : '';
  }

  public getUsernameErrorMessage(): string {
    if (this.usernameControl.hasError('required')) {
      return 'You must enter your username';
    }
    return 'Error in username entry';
  }

  public getPasswordErrorMessage(): string {
    if (this.passwordControl.hasError('required')) {
      return 'You must enter your password';
    }
    return 'Error in password entry';
  }

  public onSignInSubmit(): void {

    this.strErrorMessage = "";

    if (this.schoolCodeControl.invalid || this.usernameControl.invalid || this.passwordControl.invalid) {
      return;
    }

    this.appWebworkerService.getUserByCredentials(this.schoolCodeControl.value, this.usernameControl.value, this.passwordControl.value).subscribe((res: string) => {

      if (res.includes("nosuchuser")) {
        this.strErrorMessage = "Wrong username or password."
      } else {
        let user: any = JSON.parse(res.trim());
        if (user.groupId != 4) {
          this.strErrorMessage = "You can only login as a teacher.";
        } else {
          Globals.schoolId = this.schoolCodeControl.value;
          Globals.userEntity.id = user.id;
          Globals.userEntity.username = user.username;
          Globals.userEntity.password = user.password;
          Globals.userEntity.groupId = user.groupId;
          //todo. tokens are only used during development for now, but when hosted, should be disabled
          Globals.token = Globals.appId + "-" + Globals.schoolId + "-" + Globals.userEntity.id + "-" + Globals.userEntity.groupId;
          this.appAuthenticator.saveAuthenticatorDetails(Globals.schoolId, Globals.userEntity);
          this.appAuthenticator.signInSavedUser();
          this.router.navigate(['home']);
        }
      }
    });
  }//end method



}
