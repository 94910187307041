import { Component, OnInit } from '@angular/core';
import { AppauthenticatorService } from '../appauthenticator.service';
import { AppwebworkerService } from '../appwebworker.service';
import { CalendarDateUtil } from '../CalendarDateUtil';
import { Globals } from '../Globals';
import { TeacherEntity } from '../model/TeacherEntity';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public teacherName: string = "";


  constructor(private appAuthenticator: AppauthenticatorService, private appWebWorker: AppwebworkerService) {

    //if already set, it means already initialised before
    //use teacher id (not user id) because it is set when getting the teacher name
    if (Globals.navigateBundle.teacherId > 0) {
      this.teacherName = Globals.navigateBundle.teacherName;
    } else {
      Globals.navigateBundle.teacherUserId = Globals.userEntity.id;
      Globals.navigateBundle.yearId = CalendarDateUtil.getCurrentKenyaAcademicYear();
      Globals.navigateBundle.termId = CalendarDateUtil.getCurrentKenyaAcademicTerm();
      this.appAuthenticator.signInSavedUser();//just incase the user was deleted
      this.getTeacherDetails();
    }

  }

  ngOnInit(): void {

  }

  //todo. attach this clcik event handler to the template
  public onMarkBookEntryClick(): void {
 
    if (Globals.userEntity.id > 0 && Globals.navigateBundle.teacherId > 0) {
      //only then should the user be allowed to go to the next page
    } else {
      this.appAuthenticator.signInSavedUser();//just incase the user was deleted
      this.getTeacherDetails();

    }
  }

  private getTeacherDetails(): void {
    this.appWebWorker.getTeacherFromUserId(Globals.navigateBundle.teacherUserId).subscribe((res: string) => {
      //console.log("home teacher details reponse: " + res);
      if (res.includes("nosuchview") || res.includes("signin")) {
        //todo. highly unlikely but what should be done? hide the barks entry button?
        return;
      }

      let teacherEntity: TeacherEntity = JSON.parse(res.trim());
      Globals.navigateBundle.teacherId = teacherEntity.id;
      Globals.navigateBundle.teacherName = teacherEntity.name;
      this.teacherName = teacherEntity.name;
    });

  }



}
