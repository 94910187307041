import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { StudentMarksView } from '../model/StudentMarksView';


@Component({
  selector: 'app-marksentryinput',
  templateUrl: './marksentryinput.component.html',
  styleUrls: ['./marksentryinput.component.css']
})
export class MarksentryinputComponent implements OnInit {

  public iStudentId: number = 0;
  public strStudentName: string = "";

  @Input() objMarksView: StudentMarksView = new StudentMarksView();
  @Output() onMarksChanged: EventEmitter<StudentMarksView> = new EventEmitter();
  @Output() onEnterPressed: EventEmitter<StudentMarksView> = new EventEmitter();
  public marksInputControl: FormControl;
  public strErrorMessage: string = "";

  constructor() {
    this.marksInputControl = new FormControl("");

  }

  ngOnInit(): void {
    this.iStudentId = this.objMarksView.studentId;
    this.strStudentName = this.objMarksView.studentName

    if (this.objMarksView.marks > 0) {
      this.marksInputControl.setValue(this.objMarksView.marks, { emitEvent: false })
    }

    this.marksInputControl.valueChanges.subscribe(change => {
      this.validateValue(change);
    });


  }

  public onEnter(): void {
    this.onEnterPressed.emit(this.objMarksView);
  }

  public onFocusLost(): void {

    if (this.validateValue(this.marksInputControl.value)) {


      //if it was  previeously empty and still empty, ignore
      if ((this.marksInputControl.value === null || this.marksInputControl.value === "") && this.objMarksView.marks === 0) {
        return;
      }


      let newMarks: number = 0;
      //for empty values
      if (!isNaN(this.marksInputControl.value)) {
        newMarks = parseFloat(this.marksInputControl.value);
      }

      //console.log("Old marks: " + this.objMarksView.marks  + " | new marks: " + newMarks );

      if (this.objMarksView.marks === newMarks) {
        return;
      }

      if (this.objMarksView.marks !== newMarks) {
        this.objMarksView.marks = newMarks;
        this.onMarksChanged.emit(this.objMarksView);
      }

    }


  }

  public validateValue(value: any): boolean {

    //console.log("Value: " + value + " | Out of: " + this.objMarksView.outOf);

    this.strErrorMessage = "";

    if (value === null || value === "") {
      return true;//empt values will be interpreted as deletions
    }

    if (isNaN(value)) {
      this.strErrorMessage = 'Marks should be a number';
      return false;
    }

    let marksValue: number = parseFloat(value);
    if (marksValue < 1) {
      this.strErrorMessage = 'Marks should be > 0';
      return false;
    }

    if (marksValue > this.objMarksView.outOf) {
      this.strErrorMessage = 'Marks should be < out of';
      return false;
    }

    return true;

  }


}
