import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppwebworkerService } from '../appwebworker.service';
import { Globals } from '../Globals';
import { TeacherClassSubjectView } from '../model/TeacherClassSubjectView';

@Component({
  selector: 'app-selectsubject',
  templateUrl: './selectsubject.component.html',
  styleUrls: ['./selectsubject.component.css']
})
export class SelectsubjectComponent implements OnInit {

  public strSelectionTitle: string = "";
  public arrViews: TeacherClassSubjectView[] = []
  public arrSubjectNames: string[] = [];
  public bNoAllocation: boolean = false;
  public bLoading: boolean = false;

  constructor(private router: Router, private appWebWorker: AppwebworkerService) {
    this.loadAndSetData();
  }

  ngOnInit(): void {
  }

  public onSelectionChange(selectionButtonText: string) {
    //calculate the year and term selection
    if (selectionButtonText === "previous") {
      Globals.navigateBundle.termId = Globals.navigateBundle.termId - 1;
      if (Globals.navigateBundle.termId < 1) {
        Globals.navigateBundle.termId = 3;
        Globals.navigateBundle.yearId = Globals.navigateBundle.yearId - 1;
      }

    } else if (selectionButtonText === "next") {
      Globals.navigateBundle.termId = Globals.navigateBundle.termId + 1;
      if (Globals.navigateBundle.termId > 3) {
        Globals.navigateBundle.termId = 1;
        Globals.navigateBundle.yearId = Globals.navigateBundle.yearId + 1;
      }

    }//end if

    this.loadAndSetData();

  }

  private loadAndSetData(): void {
    //reroute to home page to start the process again
    if (Globals.navigateBundle.yearId == 0) {
      this.router.navigate(['home']);
      return;
    }

    this.strSelectionTitle = Globals.navigateBundle.yearId + " Term " + Globals.navigateBundle.termId
    this.bLoading = true;
    this.bNoAllocation = false;
    this.appWebWorker.getTeachersClassSubjects(Globals.navigateBundle.teacherId, Globals.navigateBundle.yearId, Globals.navigateBundle.termId).subscribe((res: string) => {
      //console.log("subjects reponse: " + res);
      this.bLoading = false;

      this.arrSubjectNames = [];

      if (res.includes("nosuchview") || res.includes("signin")) {
        //todo. highly unlikely but what should be done? redirect to home?
        return;
      }

      this.arrViews = JSON.parse(res.trim());
      this.bNoAllocation = this.arrViews.length === 0;
      this.arrViews.forEach((row: any) => {

        if (!this.arrSubjectNames.includes(row.subjectName)) {
          this.arrSubjectNames.push(row.subjectName);
        }

      });


    });

  }

  public setSelectedSubject(subjectName: string) {
    this.arrViews.forEach((row: TeacherClassSubjectView) => {
      if (row.subjectName === subjectName) {
        Globals.navigateBundle.subjectId = row.subjectId;
        Globals.navigateBundle.subjectAbbrv = row.subjectAbbreviation;
        Globals.navigateBundle.subjectCode = row.subjectCode;
      }
    });

  }

}
