<div class="theme-page-container">

    <app-maintoolbar></app-maintoolbar>

    <div class="theme-page-body sign-in-container">
        <div class="theme-page-title">
            <h2>Sign In</h2>
        </div>

        <form class="form-container" [formGroup]="signInForm" (ngSubmit)="onSignInSubmit()">

            <div>
                <mat-form-field appearance="standard" class="theme-form-field">
                    <mat-label>School Code</mat-label>
                    <input type="number" matInput placeholder="" [formControl]="schoolCodeControl" required />
                    <mat-hint>Enter valid school code.</mat-hint>
                    <mat-error *ngIf="schoolCodeControl.invalid">{{getSchoolCodeErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field appearance="standard" class="theme-form-field">
                    <mat-label>Username</mat-label>
                    <input type="text" matInput placeholder="" [formControl]="usernameControl" required />
                    <mat-hint>Enter valid email.</mat-hint>
                    <mat-error *ngIf="usernameControl.invalid">{{getUsernameErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field appearance="standard" class="theme-form-field">
                    <mat-label>Password</mat-label>
                    <input type="password" matInput placeholder="" [formControl]="passwordControl" required/>
                    <mat-hint>Enter valid password.</mat-hint>
                    <mat-error *ngIf="passwordControl.invalid">{{getPasswordErrorMessage()}}</mat-error>
                </mat-form-field>
            </div>


            <p class="theme-feedback">{{ strErrorMessage }}</p>
            <p class="theme-sub-text">By signing in, I hereby agree to the <a class="theme-link-text" href="https://conloopsis.co.ke/termspolicy" target="_blank">Terms & Privacy Policy</a> </p>
            <button mat-raised-button color="primary" type="submit">Sign In</button>
        </form>
    </div>
</div>