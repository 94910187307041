<mat-toolbar color="primary" class="page-header">
    <span>ConloopSIS <sup class="super-script-text">beta</sup></span>
    <span class="theme-toolbar-spacer"></span>
    <button *ngIf="bShowAccountMenuItem" mat-icon-button [matMenuTriggerFor]="accountMenu">
        <mat-icon>account_circle</mat-icon>
      </button>

    <mat-menu #accountMenu="matMenu">
        <button mat-menu-item (click)="onClickSignOut()">Sign Out</button>
    </mat-menu>
</mat-toolbar>