<mat-toolbar color="primary" class="theme-page-header selection-toolbar">
    <div class="selection-toolbar-top">
        <button mat-icon-button (click)="onClickBack()">
            <mat-icon>arrow_back</mat-icon>
          </button>
        <div class="toolbar-text-main"> <span>{{ strToolBarTitle }}</span> </div>
        <span class="theme-toolbar-spacer"></span>
        <button mat-icon-button [matMenuTriggerFor]="accountMenu">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #accountMenu="matMenu">
            <button *ngFor="let menuItem of arrExtraMenus" mat-menu-item (click)="onClickMenuItem(menuItem)">
                {{ menuItem }} 
            </button>
            <button mat-menu-item (click)="onClickSignOut()">Sign Out</button>
        </mat-menu>
    </div>
    <div class="selection-toolbar-bottom">
        <button *ngIf="bShowSelectionButtons" mat-icon-button (click)="onClickPrev()">
            <mat-icon>chevron_left</mat-icon>
          </button>
        <div class="toolbar-text-selection">{{ strSelectionTitle }}</div>
        <button *ngIf="bShowSelectionButtons" mat-icon-button (click)="onClickNext()">
            <mat-icon>chevron_right</mat-icon>
          </button>
    </div>
</mat-toolbar>