import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule,ReactiveFormsModule } from '@angular/forms'; 
import { HttpClientModule }    from '@angular/common/http'; 
import { MatCardModule } from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core'
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';//todo. not sure if will be used
import { MatListModule } from '@angular/material/list';//todo. not sure if will be used


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SigninComponent } from './signin/signin.component';
import { HomeComponent } from './home/home.component';
import { SelectsubjectComponent } from './selectsubject/selectsubject.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { MaintoolbarComponent } from './maintoolbar/maintoolbar.component';
import { SelectiontoolbarComponent } from './selectiontoolbar/selectiontoolbar.component';
import { SelectclassComponent } from './selectclass/selectclass.component';
import { SelectexamComponent } from './selectexam/selectexam.component';
import { MarksentryComponent } from './marksentry/marksentry.component';
import { MarksentryinputComponent } from './marksentryinput/marksentryinput.component';
import { MarksentryoutputComponent } from './marksentryoutput/marksentryoutput.component';
 
@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    HomeComponent,
    SelectsubjectComponent,
    PagenotfoundComponent,
    MaintoolbarComponent,
    SelectiontoolbarComponent,
    SelectclassComponent,
    SelectexamComponent,
    MarksentryComponent,
    MarksentryinputComponent,
    MarksentryoutputComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule, 
    FormsModule,ReactiveFormsModule,
    MatRippleModule,MatToolbarModule,MatIconModule,MatProgressSpinnerModule,MatButtonModule, MatListModule,MatCardModule,MatFormFieldModule,MatInputModule,MatMenuModule
 
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
