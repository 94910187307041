import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class NetworkbridgeService {

  constructor(private httpClient: HttpClient) { }

  public getRequest(url: string, mapParams: Map<string, any>): Observable<string> {
    let contentObservable: Observable<string>;
    let params: HttpParams = new HttpParams();

    mapParams.forEach(function (value, key) {
      params = params.append(key, value);
    });

    let httpOptions = {
      params: params,
      responseType: 'text' as 'json'
    };

    contentObservable = this.httpClient.get<string>(url, httpOptions).pipe(
      catchError(this.handleError)
    );

    return contentObservable;

  }

  public postRequest(url: string, mapbody: Map<string, any>): Observable<string> {
    let contentObservable: Observable<string>;
    let formattedBody = new URLSearchParams();  //this will construct and return a query string check documentation online
    let httpHeaders = new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded");
    let httpOptions = {
      headers: httpHeaders,
      responseType: 'text' as 'json'
    };

    mapbody.forEach(function (value, key) {
      formattedBody.set(key, value);
    });

    contentObservable = this.httpClient.post<string>(url, formattedBody.toString(), httpOptions).pipe(
      catchError(this.handleError)
    );

    return contentObservable;
  }

  public postJsonRequest(url: string, jsonFormattedbody: string): Observable<string> {
    let contentObservable: Observable<string>;
    let httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    let httpOptions = {
      headers: httpHeaders,
      responseType: 'text' as 'json'
    };
    contentObservable = this.httpClient.post<string>(url, jsonFormattedbody, httpOptions).pipe(
      catchError(this.handleError)
    );


    return contentObservable;
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);

      console.error('Error occurred is:', error.error.message);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  };



}
