export class StudentMarksView {
   

    public studentId: number = 0;
    public subjectId: number = 0;
    public examId: number = 0;
    public yearId: number = 0;
    public termId: number = 0;
    public teacherUserId: number = 0; //we want to be able to record null on this one
    public marks: number = 0;
    public outOf: number = 0;
    public symbol: string = "";
    public paper1Marks: number = 0;
    public paper1OutOf: number = 0;
    public paper1Symbol: string = ""; //M-missing, X-Absent, Y-Cheated
    public paper2Marks: number = 0;
    public paper2OutOf: number = 0;
    public paper2Symbol: string = ""; //M-missing, X-Absent, Y-Cheated
    public paper3Marks: number = 0;
    public paper3OutOf: number = 0;
    public paper3Symbol: string = ""; //M-missing, X-Absent, Y-Cheated


    //fields that justified by view
    public studentName: string = "";
    public percentage: number = 0;
    public grade: string = "";


}