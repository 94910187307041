import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MarksentryComponent } from './marksentry/marksentry.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { SelectclassComponent } from './selectclass/selectclass.component';
import { SelectexamComponent } from './selectexam/selectexam.component';
import { SelectsubjectComponent } from './selectsubject/selectsubject.component';
import { SigninComponent } from './signin/signin.component';



const routes: Routes = [ 
  { path: 'signin', component: SigninComponent },
  { path: 'home', component: HomeComponent },
  { path: 'selectsubject', component: SelectsubjectComponent },
  { path: 'selectclass', component: SelectclassComponent },
  { path: 'selectexam', component: SelectexamComponent },
  { path: 'marksentry', component: MarksentryComponent },
  { path: '', component: HomeComponent },
  //{ path: '', redirectTo: 'home',pathMatch: 'full'},
  { path: '**', component: PagenotfoundComponent }  // Wildcard route for a 404 page}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { } 
