<div class="theme-page-container">

    <app-maintoolbar [bShowAccountMenuItem]="true"></app-maintoolbar>

    <div class="theme-page-body">
        <div class="theme-page-title">
            <h2>Welcome</h2>
            <p>{{ teacherName }}</p>
        </div>
        <a routerLink="/selectsubject" mat-raised-button color="primary" type="submit" class="home-button">Marks Entry</a>
    </div>

</div>