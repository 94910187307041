import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppwebworkerService } from '../appwebworker.service';
import { Globals } from '../Globals';

@Component({
  selector: 'app-selectexam',
  templateUrl: './selectexam.component.html',
  styleUrls: ['./selectexam.component.css']
})
export class SelectexamComponent implements OnInit {

  public strSelectionTitle: string = "";
  public arrViews: any[] = []
  public arrExamNames: string[] = [];
  public bLoading: boolean = false;

  constructor(private router: Router, private appWebWorker: AppwebworkerService) {
    this.loadAndSetData();

  }

  ngOnInit(): void {
  }

  private loadAndSetData(): void {
    //reroute to home page to start the process again
    if (Globals.navigateBundle.yearId == 0) {
      this.router.navigate(['home']);
      return;
    }

    this.strSelectionTitle = Globals.navigateBundle.subjectAbbrv + " " + Globals.navigateBundle.classAbbrv + " " + Globals.navigateBundle.yearId + " Term " + Globals.navigateBundle.termId
    this.bLoading = true;
    this.appWebWorker.getExams(true).subscribe((res: string) => {
      this.bLoading = false;
      this.arrExamNames = [];
      if (res.includes("nosuchview") || res.includes("signin")) {
        //todo. highly unlikely but what should be done? redirect to home?
        return;
      }

      this.arrViews = JSON.parse(res.trim());
      this.arrViews.forEach((row: any) => {
        if (!this.arrExamNames.includes(row.name)) { 
          this.arrExamNames.push(row.name);
        }
      });


    });

  }

  public setSelectedExam(examName: string) { 
    this.arrViews.forEach((row: any) => {
      if (row.name === examName) {  
        Globals.navigateBundle.examId = row.id;
        Globals.navigateBundle.examAbbrv = row.abbreviation; 
      }
    });

  }


}
