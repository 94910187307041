import { Location } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AppauthenticatorService } from '../appauthenticator.service';

@Component({
  selector: 'app-selectiontoolbar',
  templateUrl: './selectiontoolbar.component.html',
  styleUrls: ['./selectiontoolbar.component.css']
})
export class SelectiontoolbarComponent implements OnInit {

  @Input() strToolBarTitle: string = "ConloopSIS Markbook";
  @Input() strSelectionTitle: string = "";
  @Input() arrExtraMenus: string[] = [];
  @Input() bShowSelectionButtons: boolean = false;
  @Output() onSelectionButtonClick: EventEmitter<string> = new EventEmitter();
  @Output() onMenuItemClick: EventEmitter<string> = new EventEmitter();


  constructor(private appAuthenticator: AppauthenticatorService, private location: Location) { }

  ngOnInit(): void {
  }

  public onClickBack(): void {
    this.location.back();
  }

  public onClickPrev(): void {
    this.onSelectionButtonClick.emit("previous");
  }

  public onClickNext(): void {
    this.onSelectionButtonClick.emit("next");
  }

  public onClickMenuItem(strMenuItem: string): void {
    this.onMenuItemClick.emit(strMenuItem);
  }

  public onClickSignOut(): void {
    this.appAuthenticator.signOut();
  }

}
