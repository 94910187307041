import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppwebworkerService } from '../appwebworker.service';
import { Globals } from '../Globals';
import { TeacherClassSubjectView } from '../model/TeacherClassSubjectView';

@Component({
  selector: 'app-selectclass',
  templateUrl: './selectclass.component.html',
  styleUrls: ['./selectclass.component.css']
})
export class SelectclassComponent implements OnInit {

  public strSelectionTitle: string = "";
  public arrViews: TeacherClassSubjectView[] = []
  public arrClassNames: string[] = [];
  public bLoading: boolean = false;

  constructor(private router: Router, private appWebWorker: AppwebworkerService) {
    //console.log("Subject: " + Globals.navigateBundle.subjectId);
    this.loadAndSetData();

  }

  ngOnInit(): void {
  }

  private loadAndSetData(): void {
    //reroute to home page to start the process again
    if (Globals.navigateBundle.yearId == 0) {
      this.router.navigate(['home']);
      return;
    }

    this.strSelectionTitle = Globals.navigateBundle.subjectAbbrv + " " + Globals.navigateBundle.yearId + " Term " + Globals.navigateBundle.termId
    this.bLoading = true;
    this.appWebWorker.getTeachersClassSubjects(Globals.navigateBundle.teacherId, Globals.navigateBundle.yearId, Globals.navigateBundle.termId).subscribe((res: string) => {
      this.bLoading = false;
      this.arrClassNames = [];

      if (res.includes("nosuchview") || res.includes("signin")) {
        //todo. highly unlikely but what should be done? redirect to home?
        return;
      }

      this.arrViews = JSON.parse(res.trim());
      this.arrViews.forEach((row: TeacherClassSubjectView) => {
        if (Globals.navigateBundle.subjectId == row.subjectId) {
          if (!this.arrClassNames.includes(row.className)) {
            this.arrClassNames.push(row.className);
          }
        }

      });


    });

  }

  public setSelectedClass(className: string) {
    let arrClassNames: string[];
    let classAbbrv: string;
    this.arrViews.forEach((row: TeacherClassSubjectView) => {
      if (Globals.navigateBundle.subjectId == row.subjectId) {
        if (row.className === className) {
          //use the last name as part of class name as  abbreviation
          // classAbbrv = "";
          // arrClassNames = row.className.split(" ");
          // if (arrClassNames.length > 0) {
          //   classAbbrv = arrClassNames[arrClassNames.length - 1];
          // }

          // classAbbrv = "Form " + row.classLevelId + " " + classAbbrv;
          Globals.navigateBundle.classId = row.classId;
          Globals.navigateBundle.classAbbrv = row.classAbbreviation;
        }
      }

    });

  }


}
