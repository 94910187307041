<div class="theme-page-container">
    <app-selectiontoolbar [strToolBarTitle]="'Select Subject'" [strSelectionTitle]="strSelectionTitle" [bShowSelectionButtons]="true" (onSelectionButtonClick)="onSelectionChange($event)"></app-selectiontoolbar>

    <div class="theme-page-body">

        <div *ngIf="bLoading">
            <mat-spinner></mat-spinner>
        </div>

        <div *ngIf="bNoAllocation">
            <p>You have not been allocated subjects.</p>
            <p>Please contact your admin to allocate you subject.</p>
        </div>

        <div *ngIf="!bNoAllocation" class="theme-card-content">
            <div *ngFor="let subjectName of arrSubjectNames">
                <a (click)="setSelectedSubject(subjectName)" routerLink="/selectclass" matRipple class="theme-card-item">
                    <div class="theme-card-item-content">
                        <div>{{ subjectName }} </div>
                    </div>
                    <div class="theme-card-item-link-icon">
                        <mat-icon class="material-icons-outlined">arrow_forward_ios</mat-icon>
                    </div>
                </a>
                <hr>
            </div>
        </div>

    </div>

</div>