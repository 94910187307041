<div class="theme-page-container">
    <app-selectiontoolbar [strToolBarTitle]="'Select Class'" [strSelectionTitle]="strSelectionTitle" [bShowSelectionButtons]="false"></app-selectiontoolbar>

    <div class="theme-page-body">
        <div *ngIf="bLoading" class="theme-spinner">
            <mat-spinner></mat-spinner>
        </div>
        <div class="theme-card-content">
            <div *ngFor="let className of arrClassNames">
                <a (click)="setSelectedClass(className)" routerLink="/selectexam" matRipple class="theme-card-item">
                    <div class="theme-card-item-content">
                        <div>{{ className }} </div>
                    </div>
                    <div class="theme-card-item-link-icon">
                        <mat-icon class="material-icons-outlined">arrow_forward_ios</mat-icon>
                    </div>
                </a>
                <hr>
            </div>
        </div>
    </div>

</div>