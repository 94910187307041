export class CalendarDateUtil {

    public static getSqlDate(dateValue: Date): string {
        let dd = String(dateValue.getDate()).padStart(2, '0');
        let mm = String(dateValue.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = dateValue.getFullYear();
        return  yyyy + '-' +  mm + '-' + dd;
    }

    public static getCurrentMonth(): number {
        //january is 0. So plus 1
        return new Date().getMonth() + 1;
    }

    public static getTerm(monthValue: number): number {
        if (monthValue < 5) {
            return 1;
        } else if (monthValue < 9) {
            return 2;
        } else {
            //(monthValue <= 12
            return 3;
        }//end if
    }

    public static getCurrentTerm(): number {
        return CalendarDateUtil.getTerm(CalendarDateUtil.getCurrentMonth());
    }

    public static getCurrentYear(): number {
        return new Date().getFullYear();
    }

    public static  getCurrentKenyaAcademicYear(): number {
        return CalendarDateUtil.getKenyaAcademicYear(CalendarDateUtil.getCurrentYear(), CalendarDateUtil.getCurrentMonth());
    }


    public static  getCurrentKenyaAcademicTerm(): number {
        return CalendarDateUtil.getKenyaAcademicTerm(CalendarDateUtil.getCurrentYear(), CalendarDateUtil.getCurrentMonth());
    }

    public static getKenyaAcademicYear(yearValue: number, monthValue: number): number {

        if (yearValue == 2021) {
            //code added on 022/07/2021 by Patowhiz at Kivani
            //follows academic calendar for 2021 and 2022 cause by covid-19
            if (monthValue > 0 && monthValue <= 4) {
                yearValue = 2020;//term 2
            } else if (monthValue >= 5 && monthValue <= 6) {
                yearValue = 2020; //term 3 
            }
        } else if (yearValue == 2022) {
            if (monthValue > 0 && monthValue <= 4) {
                yearValue = 2021;//term 3
            }
        }

        return yearValue;
    }

    public static getKenyaAcademicTerm(yearValue: number, monthValue: number): number {
        let termId: number = CalendarDateUtil.getCurrentTerm();

        if (yearValue == 2021) {
            //code added on 08/07/2021 by Patowhiz at Kivani
            //follows academic calendar for 2021 and 2022 cause by covid-19
            if (monthValue > 0 && monthValue <= 4) {
                termId = 2;//term 2
            } else if (monthValue >= 5 && monthValue <= 6) {
                termId = 3; //term 3 
            } else if (monthValue >= 7 && monthValue <= 9) {
                termId = 1;//term 1
            } else if (monthValue >= 10 && monthValue <= 12) {
                termId = 2;//term 2
            }//end if
        } else if (yearValue == 2022) {
            if (monthValue > 0 && monthValue <= 4) {
                termId = 3;//term 3
            } else if (monthValue >= 5 && monthValue <= 6) {
                termId = 1; //term 1 
            } else if (monthValue >= 7 && monthValue <= 9) {
                termId = 2;//term 2
            } else if (monthValue >= 10 && monthValue <= 12) {
                termId = 3;//term 3
            }//end if
        }

        return termId;
    }//end method


}