import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from './Globals';
import { UnsyncedAuditTrailEntity } from './model/UnsyncedAuditTrailEntity';
import { NetworkbridgeService } from './networkbridge.service';

@Injectable({
  providedIn: 'root'
})
export class AppwebworkerService {
  private domainUrl: string = "https://conloopsis.conloop.co.ke/";

  constructor(private networkbridge: NetworkbridgeService) { }


  //returns a user json if found
  public getUserByCredentials(schoolId: number, username: string, password: string): Observable<string> {
    let body = new Map<string, any>();
    body.set('a', 'usersignin');
    body.set('schoolid', schoolId);
    body.set('username', username);
    body.set('userpwd', password);
    return this.networkbridge.getRequest(this.domainUrl + "ValidateUser.php", body);
  }

  //tries to sign in the user in the server and returns "success" if signed in succesfully.
  public signInUser(schoolId: number, username: string, password: string): Observable<string> {
    let body = new Map<string, any>();
    body.set('a', 'usersignin');
    body.set('appid', Globals.appId);
    body.set('schoolid', schoolId);
    body.set('username', username);
    body.set('userpwd', password);
    return this.networkbridge.getRequest(this.domainUrl + "LogIn.php", body);
  }

  public getTeacherFromUserId(teacherUserId: number): Observable<string> {
    let body = new Map<string, any>();
    body.set('token', Globals.token);
    body.set('a', 'teacher_entity_by_user_id');
    body.set('teacher_user_id', teacherUserId);
    return this.networkbridge.getRequest(this.domainUrl + "WebViews.php", body);
  }


  public getTeachersClassSubjects(teacherId: number, yearId: number, termId: number): Observable<string> {
    let body = new Map<string, any>();
    body.set('token', Globals.token);
    body.set('a', 'teacher_subjects');
    body.set('teacher_id', teacherId);
    body.set('year_id', yearId);
    body.set('term_id', termId);
    return this.networkbridge.getRequest(this.domainUrl + "WebViews.php", body);
  }

  public getExams(bActiveExamsOnly: boolean): Observable<string> {
    let body = new Map<string, any>();
    body.set('token', Globals.token);
    body.set('a', 'exams');
    body.set('active_exams_only', bActiveExamsOnly);
    return this.networkbridge.getRequest(this.domainUrl + "WebViews.php", body);
  }

  public getStudentsMarks(teacherUserId: number, subjectId: number, classId: number, examId: number, yearId: number, termId: number,bMarksEnteredOnly: boolean): Observable<string> {
    let body = new Map<string, any>();
    body.set('token', Globals.token);
    body.set('a', 'student_marks');
    body.set('teacher_user_id', teacherUserId);
    body.set('subject_id', subjectId);
    body.set('class_id', classId);
    body.set('exam_id', examId);
    body.set('year_id', yearId);
    body.set('term_id', termId);
    body.set('marks_entered_only', bMarksEnteredOnly);
    return this.networkbridge.getRequest(this.domainUrl + "WebViews.php", body);
  }

  public saveEntity(unsyncedEntity: UnsyncedAuditTrailEntity): Observable<string> {
    let arrEntities: UnsyncedAuditTrailEntity[] = [];
    arrEntities.push(unsyncedEntity);//entities always expected as array by the server 
    return this.saveEntitities(arrEntities);
  }

  public saveEntitities(arrEntities: UnsyncedAuditTrailEntity[]): Observable<string> {
    let obj: { token: string, entities: string } = { token: Globals.token, entities: JSON.stringify(arrEntities) };
    return this.networkbridge.postJsonRequest(this.domainUrl + "UploadEntry.php", JSON.stringify(obj));
  }

}
